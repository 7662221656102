/*jshint esversion: 6 */
import YouTubePlayer from 'youtube-player';
import linkfy from 'linkfy';
import getCidades from 'cidades';

export default function() {

	$(document).ready(function() {

		var fancyboxSetting = {
			helpers : {
				title : {
					type : 'inside'
				},
				overlay : {
					locked : false
				}
			},
			afterShow: function() {
				$(':text').setMask();
			}
		};

		$('.fancy').fancybox(fancyboxSetting);

		$('[data-menu]').each(function(){
	        var menu = $(this).data('menu');
	        if ($('body').is('.'+menu)) {
	            $(this).find('a:eq(0)').addClass('actv');
	        }
	    });

		function findAgreement(lista, term) {
			var result = '';
			if(term != '' && $.trim(term).length > 0) {
				var keywords = linkfy(term).split(/\-/igm);
				result = lista.filter(function(elem){
					var texto = linkfy(elem).replace(/\-/igm,' ');
					return keywords.reduce(function(contem, keyword) {
						var regex = new RegExp(keyword,"igm");
						return regex.test(texto) && contem;
					}, true);
				}).map(function(item) {
					return '<li>'+item+'</li>';
				});
			}

			$('.agreement-results').empty().append(result);
		}

		function findAgreementByIndex(lista, index, errorMessage) {

			var result = lista.filter(function(elem){
				var regex = new RegExp('^'+index+".*","i");
				return regex.test(elem) ? elem : null;
			}).map(function(item) {
				return '<li>'+item+'</li>';
			});

			if(result != '') {
				$('.agreement-results').empty().append(result);
			} else {
				$('.agreement-results').empty().append('<li>'+errorMessage+'</li>');
			}
		}

		$('.input-agreement').on('keyup', function(){
			var agreements = $('.input-agreement').data('convenios')||[];
			var value = $(this).val();
			findAgreement(agreements, value);
		});

		$('.by-letter').on('click', function(){
			var agreements = $('.input-agreement').data('convenios')||[];
			var firstLetter = $(this).data('letter');
			findAgreementByIndex(agreements, firstLetter, 'Nenhum convênio encontrado.');
		});


		$('.input-speaker').on('keyup', function(){
			var speakers = $('.input-speaker').data('palestrantes')||[];
			var value = $(this).val();
			findAgreement(speakers, value);
		});

		$('.by-letter-speaker').on('click', function(){
			var speakers = $('.input-speaker').data('palestrantes')||[];
			var firstLetter = $(this).data('letter');
			findAgreementByIndex(speakers, firstLetter, 'Nenhum palestrante encontrado.');
		});

		$('.right-labels').on('click', function() {
			$('.right-labels').not(this).removeClass('opened');
			$(this).toggleClass('opened');
		});

		var homeSlider = {
			autoplay: true,
			arrows: false,
			dots: false,
			speed: 3000
		};

		var newsletterSlider = {
			autoplay: true,
			arrows: false,
			dots: true,
			slidesToShow: 2,
			speed: 3000
		};

		var blogSlider = {
			autoplay: true,
			arrows: true,
			dots: false,
			speed: 3000,
			prevArrow: '<i class="fa fa-chevron-left arrow arrow-left" aria-hidden="true"></i>',
			nextArrow: '<i class="fa fa-chevron-right arrow arrow-right" aria-hidden="true"></i>',
			slidesToShow: 3
		};

		

		$('.carousel-newsletter').slick(newsletterSlider);
		$('.carousel').slick(homeSlider);
		$('.carousel-blog').slick(blogSlider);

		$('.buttons-forms-area > .trigger-tab').on('click', function(){

			setTimeout(function(){
				if(!$('.buttons-forms-area > a').hasClass('active')) {

					$('.forms-area').slideUp();
				} else {
					$('.forms-area').slideDown();
				}
			}, 50);

			$('.buttons-forms-area > a').removeClass('active');
			$(this).toggleClass('active');

			var currentTab = $(this).data('tab');

			$('.form-area').removeClass('visible');
			$('.form-area.tab-'+ currentTab).addClass('visible');
		});

		$('.open-menu-trigger').on('click', function() {

			$('.offset-menu').toggleClass('menu-opened');
		});
	});

	var language = 'br';
	var Modelo = {

		init : function(){

			language = $('meta[name=language]').attr('content');

			Modelo.ajaxForm('[name=formNewsletter],[name=formContato],[name=formOrcamento],[name=formAgendarExame]');



			$('input[alt=phone]').each(function(){
				$(this).click(function () {
					$.mask.masks.phone.mask = '(99) 9999-99999';
					$(':text').setMask();
				});
				$(this).blur(function () {
					var phone, element;
					element = $(this);
					phone = element.val().replace(/\D/g, '');
					if(phone.length > 10) {
						element.setMask("(99) 99999-9999?9");
						} else {
						element.setMask("(99) 9999-9999?9");
					}
				});
			});


				
		$('body').on('click','.btn-upload',function(){
			var $self = $(this);
			var $form = $self.closest('form');
			var $el = $('<div></div>').addClass('js-fileapi-wrapper').addClass('upload-btn');
			var $input = $('<input />').attr({
				name: 'arquivo',
				type: 'file'				
			}).appendTo($el);

			var input = $input[0];
			
			$form.find('[name^=curriculo]').remove();
			$self.html("Selecionar");

			FileAPI.event.on(input,'change',function(evt){
				var files = FileAPI.getFiles(evt);

				if (files.length==0) {
					return;
				}

				$form.data('enviando',true);

				var xhr = FileAPI.upload({
					url: 'contato/upload',
					files: {
						arquivo: files[0]
					},
					progress: function(evt, file, xhr, options) {
						var pr = Math.round(evt.loaded/evt.total * 100);
						$self.trigger('progress',pr);

						$self.html(file.name+" ["+pr+"%]");
					},
					filecomplete: function(err,xhr,file) {
						$form.data('enviando',false);
						if (err) {
							$self.html("Selecionar");
							return console.log(err);
						}
						try {
							$self.html(file.name);
							var resp = JSON.parse(xhr.responseText);
							if (resp.success) {
								$.each(resp.file,function(i,val){
									var $input = $('<input />').attr({
										type: 'hidden',
										name: 'curriculo['+i+']'
									}).val(val);
									$input.appendTo($form);
								});
							}
						} catch (e) {
							$self.html("Selecionar");
							return console.log(err);
						}
					}
				});

			});


			$input.trigger('click');
		});


		$('body').on('change','[name=blogdata]',function(){
			location.href = $(this).val();
			return;
		}); 

		$('body').on('submit','[name=formTrabalheConosco]',function(){
			var $self = $(this);

			if ($self.data('enviando')) return false;
			if (!$self.find('.required').validate()) return false;

			$self.data('enviando',true);
			$.fancybox.showLoading();

			var callback = function(resp) {
				if (resp.redirect_url != undefined) {
					location.href = resp.redirect_url;
					return;
				}

				$.fancybox.hideLoading();
				$self.data('enviando',false);
				if (resp.popup_url != undefined) {

					$.fancybox.open([{
						href: resp.popup_url,
						type: ajax
					}]);
					
				}
				alert(resp.msg);
				if (resp.success) {
					$self[0].reset();
					$self.find('.btn-upload').html("Selecionar");
					$self.find('[name*=curriculo]').remove();
					$self.find('[alt=phone]').val('');
				}
			};

			$.ajax({
				url: $self.attr('action'),
				method: 'post',
				dataType: 'json',
				data: $self.serializeArray(),
				success: callback,
				error: function() {
					callback({success:false,msg:"Não foi possível enviar o formulário."});
				}
			});

			return false;
		});


		},

		ajaxForm: function(selector,cb) {
			$('body').on('submit',selector,function(){
				var $self = $(this);
				if ($self.data('enviando')) return false;
				if (!$self.find('.required').validate()) return false;

				$self.data('enviando',true);
				$.fancybox.showLoading();

				var callback = function(resp) {
					$self.data('enviando',false);
					$.fancybox.hideLoading();

					if (cb) return cb(resp,$self);
					alert(resp.msg);
					if (resp.success) {
						$self[0].reset();
						$self.find('[alt=phone]').val('');
					}
				};

				$.ajax({
					url: $self.attr('action')+'?t='+Date.now(),
					type: 'post',
					dataType: 'json',
					data: $self.serializeArray(),
					success: callback,
					error: function() {
						callback({success:false,msg:"Não foi possível enviar o formulário."});
					}
				});
				return false;
			});
		},


	};
	$(document).ready(function(){
		$(Modelo.init);
	});

	/**
		* MEDIAS
	*/
	// > 1024 pixels
	//if($(window).width() > 1024) {

	//Habilita Máscara apenas para desktops
	$(function(){
		$.mask.masks.phone.mask = '(99) 9999-99999';
		$(':text').setMask();
	});
	//}

	$(function(){
		var $img = $('#flyer img');
		if ($img.length==0) return;
		var flyer = new Image();
		flyer.onload = function() {
			$.fancybox({
				href:'#flyer',
				autoResize : false,
				autoCenter : false,
				autoSize : true
			});
		};
		flyer.src = $('#flyer img').attr('src');
	});

	$(window).on('load', function() {
		//import(/* webpackChunkName 'youtube-player' */ 'youtube-player').then(function(module) {
			//let YouTubePlayer = module.default;
			let $player = $('#player');
			if (0 == $player.length) {
				return;
			}
			let video_id = $('#player').data('id');
			let player = YouTubePlayer('player', {
				height: '360',
				width: '640',
				videoId: video_id,
				playerVars: {
					rel: 0,
					modestbranding: 1
				}
			});
			player.on('stateChange', function(event) {
				if (event.data == YT.PlayerState.PLAYING) {
				 	document.getElementById('player-trigger').style.opacity = '0';
				}

				if (event.data == YT.PlayerState.PAUSED) {
				    document.getElementById('player-trigger').style.opacity = '1';
				}

				if (event.data == YT.PlayerState.ENDED) {
					document.getElementById('player-trigger').style.opacity = '1';
				}
			});
		//});
	});

	if (0 != $('ul.accordion').length) {
		import(/* webpackChunkName 'accordion.less' */ 'accordion.less');
	}

	getCidades().then(function(cidades) {
		$('[name=cidade][data-uf]').each(function() {
			var $el = $(this);
			$el.find('option:not(:first)').remove();
			cidades.forEach(function(cidade) {
				$('<option></option>').val(cidade).html(cidade).appendTo($el);
			});
		});
	}).catch(function(e) {
		console.log(e);
	});
}

$(document).ready(function(){
	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	
	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
	
	function checkCookie() {
		var lgpd = getCookie("lgpd");
		if (lgpd == "") {
			$(".lgpd-all").show();
		}
	}
	
	checkCookie();
	
	$('.lgpd-accept').click(function(){
		setCookie("lgpd", 1, 365);
		$(".lgpd-all").hide();
	});
	
});