const cidades = fetch("https://www.studiogt.com.br/wsdl/api.php/location/getCidades?uf=RS")
	.then(function(resp){
		return resp.json();
	})
	.then(function(resp) {
		return resp.data;
	});

export default function() {
	return new Promise(function(resolve, reject) {
		cidades
			.then(resolve)
			.catch(reject);
	});
};