__webpack_public_path__ = document.baseURI+'site/static/dist/';

import 'core-js/modules/es6.symbol';
import 'core-js/modules/es6.array.iterator';
import 'core-js/modules/es6.promise';
import 'nodep-date-input-polyfill';

import $ from 'jquery';

window.$ = $;

import "fancybox/dist/js/jquery.fancybox.pack";
import "slick-carousel";
import "meiomask/dist/meiomask";
import "./validate";
import "vanilla-ripplejs";

import App from './app';


import 'foundation.min.css';

import "slick-carousel/slick/slick.less";
import "fancybox/dist/css/jquery.fancybox.css";

import 'style.less';



if (!window.fetch) {
	import(/* webpackChunkName: "polyfills" */ "./polyfills").then(function() {
		console.log('with polyfill');
		App();
	});
} else {
	console.log('without polyfill');
	App();
}

$(window).on('load', function() {
	import(/* webpackChunkName: "fontes */ "fontes.css");
	import(/* webpackChunkName: "fontes */ "font-awesome.min.css");
});